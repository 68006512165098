import {
  Center,
  Divider,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { RiStarFill } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectCart } from "src/components/store/cart/cartSlice";
import {
  selectStoreModal,
  showModal,
} from "src/components/store/storeModal/storeModalSlice";
import { setProductReviews } from "../../storeProductsSlice";
import useMediaQuery from "src/hooks/useBetterMediaQuery";
import numberFormatter from "src/modules/numberFormatter";
import { useRouter } from "next/router";

export default function ProductCard({
  id,
  slug,
  name,
  image,
  lowestPrice,
  highestPrice,
  rating,
  isOutOfStock = false,
  isInStore = false,
  merchantSlug,
  inStorePage = false,
  isStorePreview = false,
}) {
  const dispatch = useAppDispatch();
  const [price, setPrice] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const { isVisible } = useAppSelector(selectStoreModal);
  const { cartDetails } = useAppSelector(selectCart);
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const router = useRouter();

  const onProductClick = async () => {
    if (isStorePreview) {
      return;
    }
    if (localStorage?.getItem("selectedLocation")) {
      setIsloading(true);
      dispatch(setProductReviews(null));
      if (isInStore || inStorePage) {
        router.push(`/store/product/${slug}?store=${merchantSlug}`);
      } else {
        router.push(`/store/product/${slug}`);
      }
      return true;
    } else {
      if (cartDetails && cartDetails.total_items) {
        await dispatch(showModal({ modalType: "clear-cart-modal" }));
      } else {
        await dispatch(showModal({ modalType: "select-location-modal" }));
      }
    }
    return false;
  };

  useEffect(() => {
    if (lowestPrice === highestPrice || highestPrice === null) {
      const formattedLowPrice = numberFormatter
        .formatNumberToPHCurrencyInteger(lowestPrice)
        .slice(1);
      setPrice(`₱${formattedLowPrice}`);
    } else {
      const formattedLowPrice = numberFormatter
        .formatNumberToPHCurrencyInteger(lowestPrice)
        .slice(1);
      const formattedHighPrice = numberFormatter
        .formatNumberToPHCurrencyInteger(highestPrice)
        .slice(1);
      setPrice(`₱${formattedLowPrice} - ₱${formattedHighPrice}`);
    }
  }, [lowestPrice, highestPrice]);

  return (
    <>
      <VStack
        spacing="8px"
        pt={["12px", "22px"]}
        pb="0px"
        px={["12px", "22px"]}
        w={isDesktop ? "95%" : "98%"}
        h="auto"
        minH={["265px", "290px"]}
        borderRadius="8px"
        border={isInStore ? "1px solid #E0E9EE" : "0.5px solid #E0E9EE"}
        boxShadow={isInStore ? "none" : "md"}
      >
        <div
          onClick={() => {
            onProductClick();
          }}
          className="cursor-pointer"
        >
          <VStack spacing="-20px">
            {isOutOfStock && (
              <HStack
                mr={["-0px", "-10px"]}
                bg="#117BD4"
                px={2}
                py={0.5}
                borderRadius="100px"
                placeSelf="flex-end"
                zIndex={0}
              >
                <Text
                  color="#ffffff"
                  fontWeight={500}
                  fontFamily="CeraProBold"
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  placeSelf="flex-start"
                >
                  sold out
                </Text>
              </HStack>
            )}
            <Center w="100%">
              <Image
                src={image}
                w="166px"
                h="133px"
                objectFit="contain"
                alt={name}
              />
            </Center>
          </VStack>
        </div>

        <HStack w="100%" minH="44px">
          <Text
            placeSelf="flex-end"
            px="0px"
            w="100%"
            fontSize={["15px", "16px"]}
            pt={[3, 4]}
            fontWeight={500}
            fontFamily="CeraPro"
            lineHeight={["16px", "18px"]}
            color="#214151"
            textAlign="left"
            noOfLines={[2, 2, 2, 2]}
            maxWidth="150px"
          >
            {name}
          </Text>

          <HStack placeSelf="flex-end">
            {Number(rating) && (
              <>
                <Icon as={RiStarFill} color="#E4C109" boxSize="16px" />
                <Text
                  fontFamily="CeraPro"
                  fontWeight={500}
                  fontSize={["12px", "14px"]}
                >
                  {Number(rating).toFixed(1)}
                </Text>
              </>
            )}
          </HStack>
        </HStack>

        <Divider color="#E0E9EE" />

        <HStack w="100%" pb={2}>
          <Text
            fontSize={["14px", "16px"]}
            w="100%"
            fontWeight={500}
            fontFamily="CeraPro"
            color="#117BD4"
            textAlign="left"
          >
            {price}
          </Text>

          <Spacer />

          <div
            onClick={() => {
              onProductClick();
            }}
            className="cursor-pointer"
          >
            <Center
              w={["35px", "45px"]}
              h={["35px", "45px"]}
              bg="#117BD4"
              borderRadius="8px"
            >
              <Icon as={BsPlus} color="white" boxSize={["22.5px", "32.5px"]} />
            </Center>
          </div>
        </HStack>
      </VStack>
    </>
  );
}
