export const PUT = 'PUT';
export const POST = 'POST';
export const DELETE = 'DELETE';
export const GET = 'GET';

export const bearerHeader = (token) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
}

export const defaultAPIHeader = {
    'Content-Type': 'application/json',
}