import {
  Box,
  Container,
  HStack,
  Icon,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { SPONSORED_PRODUCTS_TAG } from "lib/Constants";
import NextLink from "next/link";
import { useMemo } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import Slider from "react-slick";
import ProductCard from "src/components/store/storeProducts/templates/shared/ProductCard";
import useMediaQuery from "src/hooks/useBetterMediaQuery";
import getPriceRange from "src/modules/getPriceRange";
import cn from "classnames";

const StoreSponsoredProducts = ({
  sponsored_products,
  title,
  ids,
  inStorePage = false,
  isStorePreview = false,
  merchantSlug,
  tag,
}) => {
  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const productsCount = inStorePage ? 5 : 6;

  const slidesToShow = useMemo(() => {
    if (isDesktop == null) return;
    if (isDesktop == true) {
      return sponsored_products?.length < productsCount
        ? sponsored_products?.length
        : productsCount;
    } else {
      return sponsored_products?.length < 2 ? sponsored_products?.length : 2;
    }
  }, [sponsored_products, isDesktop]);

  const sponsoredSettings = {
    dots: false,
    infinite: true,
    speed: 3,
    slidesToShow: slidesToShow,
    arrows: isDesktop == true ? true : false,
    slidesToScroll: isDesktop == true ? 2 : 1,
    swipeToSlide: true,
  };

  return (
    <>
      {sponsored_products && sponsored_products.length > 0 && (
        <Container
          minW="100%"
          px={["12px", inStorePage ? "40px" : "60px"]}
          pt={inStorePage ? "20px" : "40px"}
          pb={inStorePage ? "20px" : "0px"}
        >
          <VStack w="100%" py={2} spacing={0}>
            <Box w="full" className="flex justify-between items-center" pb={3}>
              <HStack
                placeSelf="flex-start"
                justifyContent="center"
                spacing={5}
              >
                <Text
                  color="#214151"
                  fontWeight={500}
                  fontFamily="CeraPro"
                  fontSize={["20px", "20px", "20px", "22px"]}
                  w="100%"
                  textAlign="left"
                >
                  {title}
                </Text>
                {!inStorePage && (
                  <Text
                    color="gray.500"
                    borderRadius={6}
                    bg="gray.100"
                    px={2}
                    fontWeight={700}
                    fontFamily="CeraPro"
                    fontSize={["10px", "10px", "10px", "12px"]}
                    textAlign="center"
                    className="!ms-px-2 me-3"
                  >
                    Sponsored
                  </Text>
                )}
              </HStack>

              <Box placeSelf="flex-center" className="pr-2">
                {!isStorePreview ? (
                  <>
                    {inStorePage ? (
                      <Link
                        fontFamily="CeraPro"
                        fontWeight={500}
                        fontSize={["14px", "16px"]}
                        color="#117BD4"
                        as={NextLink}
                        href={{
                          pathname: `${process.env.NEXT_PUBLIC_URL}store/merchant/${merchantSlug}/featured`,
                          query: {
                            tag,
                          },
                        }}
                        _focus={{ bg: "white", textDecoration: "none" }}
                        _active={{ bg: "white", textDecoration: "none" }}
                        _hover={{ bg: "white", textDecoration: "none" }}
                      >
                        <div className="flex justify-center items-center gap-1">
                          <div className="whitespace-nowrap">See all</div>
                          <Icon
                            as={HiArrowNarrowRight}
                            boxShadow={8}
                            color="#117BD4"
                          />
                        </div>
                      </Link>
                    ) : (
                      <Link
                        fontFamily="CeraPro"
                        fontWeight={500}
                        fontSize={["14px", "16px"]}
                        color="#117BD4"
                        as={NextLink}
                        href={{
                          pathname: `${process.env.NEXT_PUBLIC_URL}store/[slug]`,
                          query: {
                            slug: SPONSORED_PRODUCTS_TAG,
                            ids,
                            title,
                          },
                        }}
                        _focus={{ bg: "white", textDecoration: "none" }}
                        _active={{ bg: "white", textDecoration: "none" }}
                        _hover={{ bg: "white", textDecoration: "none" }}
                      >
                        <div className="flex justify-center items-center gap-1">
                          <div className="whitespace-nowrap">See all</div>
                          <Icon
                            as={HiArrowNarrowRight}
                            boxShadow={8}
                            color="#117BD4"
                          />
                        </div>
                      </Link>
                    )}
                  </>
                ) : (
                  <div className="flex justify-center items-center gap-1">
                    <div className="whitespace-nowrap">
                      <Text
                        fontFamily="CeraPro"
                        fontWeight={500}
                        fontSize={["14px", "16px"]}
                        color="#117BD4"
                      >
                        See all
                      </Text>
                    </div>
                    <Icon
                      as={HiArrowNarrowRight}
                      boxShadow={8}
                      color="#117BD4"
                    />
                  </div>
                )}
              </Box>
            </Box>

            <Box w="100%">
              <Slider {...sponsoredSettings}>
                {sponsored_products
                  .sort((a, b) => a.product_order - b.product_order)
                  .map((i) => {
                    if (!i.stores) return;
                    const range = getPriceRange(i.stores);
                    let isOutOfStock = i.is_out_of_stock;
                    if (inStorePage) {
                      isOutOfStock = i.available_stock === 0;
                    }

                    return (
                      <ProductCard
                        key={i.id}
                        id={i.id}
                        slug={i.slug}
                        name={i.name}
                        image={i.image_url}
                        lowestPrice={range.lowest_price}
                        highestPrice={range.highest_price}
                        rating={i.rating}
                        isOutOfStock={isOutOfStock}
                        inStorePage={inStorePage}
                        isStorePreview={isStorePreview}
                        merchantSlug={merchantSlug}
                      />
                    );
                  })}
              </Slider>
            </Box>
          </VStack>
        </Container>
      )}
    </>
  );
};

export default StoreSponsoredProducts;
