import { removeNonNumerics } from "./removeNonNumerics";

const getPriceRange = (stores) => {
  const prices = [];
  
  stores.map((store) => {
    const storeInfo = store.split(',');
    const originalPrice = removeNonNumerics(storeInfo[0]);
    const discountAmount =  removeNonNumerics(storeInfo[1]);
    const discountedPrice =  originalPrice - discountAmount;
    prices.push(discountedPrice)
  })

  return {lowest_price: Math.min(...prices), highest_price: Math.max(...prices)};
}

export default getPriceRange;