import verifyToken from "src/modules/verifyToken";
import { USER_TOKEN } from "./Constants";
import ReactGA from "react-ga4";
import { DEFAULT_EVENT_TRACKER_EVENT, DEFAULT_EVENT_TRACKER_TYPE, DEFAULT_GUEST_VALUE, DEFAULT_REGISTERED_VALUE } from "@/constants";

export const onClickBanner = (bannerUrl, imageUrl, category) => {
  if (bannerUrl && imageUrl) {
    try {
      ReactGA.event({ category: `${bannerUrl}`, action: 'Category Banner Click' });
      const imageKey = imageUrl.split('/')[4];
      const encodedBannerUrl = encodeURIComponent(bannerUrl);
      const encodedImageKey = encodeURIComponent(imageKey);
      const event = DEFAULT_EVENT_TRACKER_EVENT;
      const type = DEFAULT_EVENT_TRACKER_TYPE;
      const device_info = {
        platform: navigator.platform,  
      };

      let user_type = DEFAULT_GUEST_VALUE;
      let user_id = null;
      const userToken = localStorage.getItem(USER_TOKEN);
      const verifiedToken = verifyToken(userToken, USER_TOKEN);
      if (verifiedToken) {
        user_type = DEFAULT_REGISTERED_VALUE;
        user_id = verifiedToken.user;
      }
  
      let redirectionUrl = `/api/event_tracker?url=${encodedBannerUrl}&device_info=${device_info.platform}&image=${encodedImageKey}&event=${event}&type=${type}&user_type=${user_type}&category=${category}`;
      if (user_id) {
        redirectionUrl = redirectionUrl.concat(`&user_id=${user_id}`);
      }
        
      window.open(redirectionUrl, '_blank');
    } catch (error) {
      console.error("Error in redirection:", error);
    }
  }
}