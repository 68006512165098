const formatNumberToPHCurrencyInteger = (num) => {
  const formatter = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 0,
  });

  return formatter.format(num);
};

const formatNumberToPHCurrency = (num) => {
  const formatter = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
  });

  return formatter.format(num);
};

export default { formatNumberToPHCurrencyInteger, formatNumberToPHCurrency };
  